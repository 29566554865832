import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallets: [],
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    addWallet: (state, action) => {
      const existed = state.wallets.find((wallet) => wallet._id === action.payload._id);
      // Avoid add same wallet twice
      if (!existed) {
        state.wallets = [...state.wallets, action.payload];
      }
    },
    removeWallet: (state, action) => {
      state.wallets = state.wallets.filter((wallet) => wallet._id !== action.payload.removeId);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWallets, addWallet, removeWallet } = walletSlice.actions;

export default walletSlice.reducer;
