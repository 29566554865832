import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL + "/graphql",
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosInstanceREST = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL + "/web",
  headers: {
    "Content-Type": " multipart/form-data",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong"),
);

axiosInstanceREST.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong"),
);

export default axiosInstance;
