import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search topics…",
      "Welcome back": "Welcome back",
      "We've missed you": "We've missed you",
    },
    role: {
      ESG_ADMIN: "Administrator",
      ESG_MANAGER: "Manager",
      ESG_AGENT: "Saler",
    },
  },
  fr: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bon retour",
      "We've missed you": "Tu nous as manqué",
      percent2d: "{{val, number(maximumFractionDigits: 2)}}%",
      percent1d: "{{val, number(maximumFractionDigits: 1)}}%",
      percent0d: "{{val, number(maximumFractionDigits: 0)}}%",
      float2: "{{val, number(maximumFractionDigits: 2)}}",
      int: "{{val, number(maximumFractionDigits: 0)}}",
      rating: "{{val, number(maximumFractionDigits: 1)}}",
      intlDateTime: "{{val, datetime}}",
      exclusions: {
        FOSSIL_EXTRACTION: "Énergies fossiles",
        WATER_CYCLE: "Cycle de l’eau",
        POLLUTING_TRANSPORT: "Transports polluants",
        FOSSIL_USAGE: "Utilisation d’énergies fossiles",
        NUCLEAR: "Nucléaire",
        WEAPONS: "Armement",
        ILLEGAL_WORK: "Travail illégal",
        TAX_AVOIDANCE: "Paradis fiscaux",
      },
      importStatus: {
        PROCESSING: "En cours de traitement",
        DONE: "Terminée",
        ERROR: "En erreur",
      },
      role: {
        ESG_ADMIN: "Administrateur",
        ESG_MANAGER: "Manager",
        ESG_AGENT: "Opérateur",
      },
    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Welcome back": "Willkommen zurück",
      "We've missed you": "Wir haben dich vermisst",
    },
  },
  nl: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welkom terug",
      "We've missed you": "We hebben je gemist",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});
